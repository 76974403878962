// Copyright 2021
// Polus
import * as React from 'react';
import { ArrowUpIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';

type ArticlePropsType = {
  headline: string;
  datetimeLabel: string;
  isHiglighted?: boolean;
  url: string;
  isInternalURL?: boolean;
  sourceLabel: string;
};

const Article = ({
  headline,
  datetimeLabel,
  isHiglighted,
  url,
  sourceLabel,
  isInternalURL = false,
}: ArticlePropsType) => {
  const row = (
    <>
      <div>
        <p className={`font-medium group-hover:text-blue-500 ${isHiglighted ? 'font-bold' : ''}`}>
          {headline}
        </p>
        <p className="text-sm text-stone-500">{datetimeLabel}</p>
      </div>
      <div className="flex flex-row space-x-1">
        {!isInternalURL && (
          <span className="hidden text-sm font-medium text-blue-400 md:group-hover:block">
            {sourceLabel}
          </span>
        )}
        <ArrowUpIcon aria-hidden="true" className="h-5 w-5 rotate-45 group-hover:text-blue-500" />
      </div>
    </>
  );

  const linkClassName =
    'group flex cursor-pointer flex-row justify-between p-4 hover:bg-indigo-100';

  if (isInternalURL) {
    return (
      <Link className={linkClassName} to={url}>
        {row}
      </Link>
    );
  }

  return (
    <a className={linkClassName} href={url} rel="noreferrer" target="_blank">
      {row}
    </a>
  );
};

export default Article;
