// Copyright 2021
// Polus
import * as React from 'react';
import { graphql } from 'gatsby';
import { format, parseISO } from 'date-fns';

import Layout from '../../components/Layout';
import Article from '../../components/blog/Article';
import Seo from '../../components/SEO';

const Blog = ({ data }): React.ReactElement => {
  const articleNodes = data.allMarkdownRemark.edges.map((edgeNode) => edgeNode.node);

  const articlesSortedByDate = articleNodes
    .slice()
    .sort(
      (edgeNodeA, edgeNodeB) =>
        new Date(edgeNodeB.frontmatter.date).getTime() -
        new Date(edgeNodeA.frontmatter.date).getTime(),
    );

  return (
    <Layout>
      <Seo title="Blog" />
      <div className="flex flex-col divide-y-2">
        {articlesSortedByDate.map(
          ({ html, frontmatter: { slug, highlight, date, title, url } }) => {
            const hasInternalPost = html != null && html !== '';
            const isHighlighted = highlight != null && highlight === true;

            return (
              <Article
                key={slug}
                datetimeLabel={`Published on ${format(parseISO(date), 'LLLL do, yyyy')}`}
                headline={title}
                isHiglighted={isHighlighted}
                isInternalURL={hasInternalPost}
                sourceLabel={hasInternalPost ? 'Read on our blog' : 'Read on Medium'}
                url={hasInternalPost ? `/blog/${slug}` : url}
              />
            );
          },
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          html
          headings {
            depth
            value
          }
          frontmatter {
            title
            date
            highlight
            url
            slug
          }
        }
      }
    }
  }
`;

export default Blog;
